import VueJSModal from "vue-js-modal";

export default class Popup {
    constructor({component, props = {}, params = {}, events = {}}) {
        this.component = component;
        this.props = props;
        this.params = params;
        this.events = {
            'opened': () => {
                Object.assign(component, {modalName: params.name});
            },
            'closed': () => {
            }
        };
        
        Object.assign(this.events, events);
    }
    
    show() {
        VueJSModal.rootInstance.$modal.show(this.component, this.props, this.params, this.events);
    }
    
    hide() {
        VueJSModal.rootInstance.$emit('close');
    }
};
