<template>
    <div class="modal-popup" @keyup.13="onSaveClick">
        <div class="mb-3 modal-popup__title">
            Редактирование жанра

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <input type="text" class="form-control mb-2" placeholder="Название" v-model="name" ref="input">
        <ui-button class="mt-3 mb-2" @clicked="onSaveClick">Сохранить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: {
        genreId: String
    },
    data: () => ({
        name: '',
        alert: ''
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    computed: {
        ...mapGetters('firebase', ['genres']),
    },
    mounted() {
        this.name = this.genres[this.genreId]?.Name || '';
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('firebase', ['saveGenre']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onSaveClick() {
            if (this.name === '') {
                this.setAlert('Необходимо ввести название');
                return;
            }

            const response = await this.saveGenre({id: this.genreId, name: this.name});

            if (response.error)
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            else
                this.close();
        },
        close() {
            this.$modal.hide('edit-genre-modal');
        }
    }
};
</script>
