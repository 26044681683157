import Popup from "@/lib/Popup";
import EditCurrencyModal from '@/components/modals/EditCurrencyModal';

export const openEditCurrencyModal = currency => {
    const popup = new Popup({
        component: EditCurrencyModal,
        props: {currency},
        params: {
            transition: 'scale',
            name: 'edit-currency-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openEditCurrencyModal};
