import Popup from "@/lib/Popup";
import AddCurrencyModal from '@/components/modals/AddCurrencyModal';

export const openAddCurrencyModal = () => {
    const popup = new Popup({
        component: AddCurrencyModal,
        props: {},
        params: {
            transition: 'scale',
            name: 'add-currency-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddCurrencyModal};
